<template>
    <el-container class="page-container">
        <el-header class="page-header" style="min-height: 60px;height: auto">
            <el-form label-position='left' label-width="50px" :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="时间:">
                            <el-date-picker
                                    v-model="time"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    style="width: auto"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="status" label="用户:">
                            <el-input v-model="query.userName" placeholder="请输入用户名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </el-header>
        <el-main class="page-main" style="padding: 0 20px">
            <el-table
                    :data="tableData"
                    ref="multipleTable"
                    style="width: 100%">

                <el-table-column
                        prop="userid"
                        label="userID"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="username"
                        label="用户名称"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="amount"
                        label="分润总金额"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="cash_amount"
                        label="已提现金额"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="recoverable_amount"
                        label="可提现金额"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="创建时间"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        label="跟新时间"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="180"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="handleEdit(scope.row)" plain>查看返点详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
<!--            <el-table-->
<!--                    :data="tableData"-->
<!--                    style="width: 100%"-->
<!--                    row-key="id"-->
<!--                    border-->
<!--                    lazy-->
<!--                    :load="load"-->
<!--                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">-->
<!--                <el-table-column-->
<!--                        prop="userid"-->
<!--                        label="userID"-->
<!--                        :show-overflow-tooltip="true"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="username"-->
<!--                        label="用户名称"-->
<!--                        :show-overflow-tooltip="true"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="amount"-->
<!--                        label="分润总金额"-->
<!--                        :show-overflow-tooltip="true"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="cash_amount"-->
<!--                        label="已提现金额"-->
<!--                        :show-overflow-tooltip="true"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="recoverable_amount"-->
<!--                        label="可提现金额"-->
<!--                        :show-overflow-tooltip="true"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="create_time"-->
<!--                        label="创建时间"-->
<!--                        :show-overflow-tooltip="true"-->
<!--                >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="update_time"-->
<!--                        label="跟新时间"-->
<!--                        :show-overflow-tooltip="true"-->
<!--                >-->
<!--                </el-table-column>-->
<!--            </el-table>-->
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
        <el-dialog title="查看详情" :visible.sync="visibleFormDialog" :fullscreen="true">
            <el-form label-position='left' label-width="50px" :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="时间:">
                            <el-date-picker
                                    v-model="time1"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    style="width: auto"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearchSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearchSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
            <el-table
                    :data="tableDataDetail"
                    ref="multipleTable"
                    style="width: 100%">
<!--                <el-table-column-->
<!--                        prop="userid"-->
<!--                        label="userID"-->
<!--                        :show-overflow-tooltip="true"-->
<!--                >-->
<!--                </el-table-column>-->
                <el-table-column
                        prop="username"
                        label="用户名称"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="level"
                        label="等级"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="goodsname"
                        label="所售商品"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="goodprice"
                        label="售价"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="amount"
                        label="所获返利"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="rebateusername"
                        label="返利用户名"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="创建时间"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChangeSearch"
                    @current-change="handleCurrentChangeSearch"
                    :current-page="pageInfoSearch.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfoSearch.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfoSearch.totalCount">
            </el-pagination>
            <div class="flex flex-direction-row-reverse btn-group" style="margin-top: 20px">
                <el-button @click="closeModul">关 闭</el-button>
            </div>
        </el-dialog>

    </el-container>

</template>

<script>
  import {mapGetters} from 'vuex'
  import formDialog from '../../components/formDialog'
  import {shopData,shopAdd,shopEdit,shopDeleteOne,shopDeleteMore,getErcode} from '../../api/firmManage'
  import {subdivisionData,logoData} from '../../api/distributeManage'
  import moment from 'moment'
  export default {
    name: "companyManagement",
    components: {formDialog},
    data() {
      let validatemobile = (rule, value, callback) => {
        if (!value) {
          callback(new Error('联系电话'));
        }else if((!(/^1[3456789]\d{9}$/.test(value)))){
          callback(new Error('请输入正确的联系电话'));
        } else {
          callback();
        }
      };
      return {
        tableData: [],
        tableDataDetail:[],
        pageInfo: {
          totalCount: 0,
          pageNumber: 1,
          pageSize: 10,
          pageOrderName:'opAt',
          pageOrderBy:'descending'
        },
        pageInfoSearch:{
          totalCount: 0,
          pageNumber: 1,
          pageSize: 10,
          pageOrderName:'create_time',
        },
        time:'',
        time1:'',
        query: {
          userName:''
        },
        visibleFormDialog:false,
        subdivisionId:null,
      }
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),
    },
    methods: {
      handleSearch() {
        this.pageInfo.pageNumber = 1;
        this.subdivisionData()
      },
      handleSearchSearch(){
        this.pageInfoSearch.pageNumber = 1;
        this.logoData();
      },
      async subdivisionData() {
        try {
          let obj=!this.time?{}:{startTime:new Date(this.time[0]+' 00:00:00').getTime()/1000,endTime:new Date(this.time[1]+' 23:59:59').getTime()/1000}
          let query = Object.assign({}, this.pageInfo, this.query,obj)
          let formData = {}
          for (let i in query) {
            if (query[i] !== '' && i !== 'total' && query[i] !== -1) {
              formData[i] = query[i]
            }
          }
          let resonse = await subdivisionData(formData)
          let {data} = resonse;
          console.log(392,data);
          data.list.forEach(curr=>curr.hasChildren=true)
          this.tableData = data.list;
          this.pageInfo.totalCount = data.totalCount;
        } catch (e) {
          console.log(e)
        }
      },

      clearSearch() {
        this.query={
          userName: '',
        },
          this.time='';
          this.handleSearch()
      },
      clearSearchSearch() {
        this.time1='';
        this.handleSearchSearch()
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.pageInfo.page = 1
        this.subdivisionData()
        console.log(`每页 ${val} 条`);
      },
      handleSizeChangeSearch(val){
        this.pageInfoSearch.pageSize = val
        this.pageInfoSearch.page = 1
        this.logoData();
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNumber = val
        this.subdivisionData()
      },
      handleCurrentChangeSearch(val){
        this.pageInfoSearch.pageNumber = val
        this.logoData();
      },
      //查看
      async handleEdit(row) {
        console.log(426,row)
        this.visibleFormDialog = true;
        this.subdivisionId=row.id;
        await this.logoData();
      },
      async logoData(){
        try {
          // let formData = {subdivisionId:row.id};
          let obj=!this.time1?{}:{startTime:new Date(this.time1[0]+' 00:00:00').getTime()/1000,endTime:new Date(this.time1[1]+' 23:59:59').getTime()/1000}
          let formData = Object.assign({}, this.pageInfoSearch, {subdivisionId:this.subdivisionId},obj)
          let {data} = await logoData(formData);
          // console.log(415,data.list);
          this.tableDataDetail=data.list;
          this.pageInfoSearch.totalCount = data.totalCount;
        }catch (e) {

        }
      },
      closeModul(){
        this.visibleFormDialog = false;
      },
    },
    mounted() {
      this.subdivisionData()
    }
  }
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }

    }
    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }
    .el-input__prefix{
        left: 15px;
        color: #303133;
        i{
            font-style:normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }

</style>
